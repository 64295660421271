(function () {'use strict';}());
angular.module("app").directive('bullets', function($document,$timeout) {
    return {
      restrict: 'A',

      link:function($scope,element,attr)
      {
        var trace = angular.trace;
        $scope.renderingBullet = false;
        var $requested;
        var id = parseInt(attr.bullets);

        $scope.$on("advanceBullet", function(e, value){

        	if(value !== id) return;
        	var $page = $("#page-"+id);
        	var $current = $page.find(".button.active");
        	var $list = $current.closest(".ul");
        	var index = $current.closest(".li").index();
        	var $target = $list.find(".li").eq(index+1).find(".button");


        	processClick($target);
        });


        $scope.onBulletClick = function($event) {
        	if($scope.renderingBullet === true) return;
        	processClick($($event.currentTarget));
        	
        };

        function processClick($target) {
        	var $nav = $target.closest(".ul");
        	var $screen = $("#bullet-screen-"+$target.data("bullet"));
        	$requested = $screen;
        	$scope.renderingBullet = true;
        	$nav.find(".active").removeClass("active");
        	$target.addClass("active");

        	$timeout(changeBullet,250);
        }

        function changeBullet() {
        	var $parent = $requested.closest(".bullet-screens");
        	$parent.find(".render").removeClass("render");
        	$requested.addClass("render");
        	$scope.renderingBullet = false;
        }
      }  
    };
  });