(function () {'use strict';}());

angular.module('app')
  .controller('AppController', function ($scope,$timeout,AppService) {
  	var trace = angular.trace;
  	var TRANSITION_SPEED = 250;
    var preloaded = [];

    $scope.pages = [];
    $scope.currentPageIndex = -1;
  	$scope.currentPage = null;
  	$scope.requestedPage = null;
  	$scope.transitioning = false;
    $scope.isBulletsPage = false;
    $scope.menuOpen = false;
  	$scope.containerClasses = {transitioning: false, ready: false, collapsed: true, hideContent: true};

    $scope.toggleMenu = function() {
      $scope.menuOpen = !$scope.menuOpen;

      if($scope.menuOpen) openMenu();
      else closeMenu();
    };

    function openMenu() {
      $scope.containerClasses.hideContent = false;
      $scope.containerClasses.expanding = true;
      $scope.containerClasses.collapsed = false;
      $timeout(completeOpenMenu,TRANSITION_SPEED);
    }

    function completeOpenMenu() {
      $scope.containerClasses.expanding = false;
      $scope.containerClasses.expanded = true;
    }

    function closeMenu () {
      $scope.containerClasses.collapsing = true;
      $scope.containerClasses.expanded = false;
      $timeout(completeCloseMenu,TRANSITION_SPEED);
    }

    function completeCloseMenu() {
      $scope.containerClasses.collapsing = false;
      $scope.containerClasses.collapsed = true;
      $timeout(function(){$scope.containerClasses.hideContent = true;}, TRANSITION_SPEED);
    }

  	$scope.advancePage = function() {
  		var currentPageIndex = $scope.pages.indexOf($scope.currentPage);
  		if(currentPageIndex === $scope.pages.length - 1) return;

      if($scope.isBulletsPage) return advanceBulletScreen();
  		$scope.setCurrentPage($scope.pages[currentPageIndex+1].id);
  	};
  	
  	$scope.setCurrentPage = function(id) {
  		if($scope.containerClasses.transitioning === true) return;
  		$scope.containerClasses.transitioning = true;
  		$scope.requestedPage = id;
  		$timeout(setNewPage,TRANSITION_SPEED);
  	};

  	function setNewPage() {
  		var page = $scope.pages.filter(function(e) {return parseInt($scope.requestedPage) === e.id; })[0];
  		$scope.currentPage = page;
  		$scope.requestedPage = null;
      $scope.currentPageIndex = $scope.pages.indexOf(page);
      window.scrollTo(0,0);
      $scope.isBulletsPage = handleBullets(page);
  		$timeout(cleanupTransition,TRANSITION_SPEED);
  	}

  	function cleanupTransition() {
  		$scope.containerClasses.transitioning = false;
      $scope.isBulletsPage = handleBullets($scope.currentPage);
      preloadNextPageImages();
  	}

    function handleBullets(page) {
      var $page = $("#page-"+page.id);
      var isBulletsPage = $page.hasClass("learning-module-bullets-screen");
      if(!isBulletsPage) return false;

      $page.find(".active").removeClass("active");
      $page.find(".render").removeClass("render");
      $page.find(".bullet-nav .button").eq(0).addClass("active");
      $page.find(".bullet-screen").eq(0).addClass("render");
      return true;
    }

    function advanceBulletScreen() {
      var $page = $("#page-" + $scope.currentPage.id);
      var $current = $page.find("button.active");
      var $list = $current.closest(".ul");
      var index = $current.closest("li").index();
      var length = $list.find(".li").length;

      if(index === length - 1) {
        $scope.isBulletsPage = false;
        $scope.advancePage();
        return;
      }

      $scope.$broadcast("advanceBullet",$scope.currentPage.id);
    }

    function preloadNextPageImages() {
      var index = $scope.pages.indexOf($scope.currentPage);
      var images,page;
      if(index >= $scope.pages.length - 1) return;
      page = $scope.pages[index+1];

      images = __IMAGES__.filter(function(e){return e.pageId === page.id; });
      images.forEach(function(image){
        var obj = new Image();
        obj.src = image.src;
        preloaded.push(obj);
      });
    }

  	(function initialize(){
  		$scope.pages = __PAGES__;
      $scope.pages.forEach(function(e){e.id = parseInt(e.id);});
      $scope.containerClasses.ready = true;
  		$scope.setCurrentPage($scope.pages[0].id);
  	}());
  });
