(function () {'use strict';}());
angular.module("app").directive('quiz', function($document,$timeout) {
    return {
      restrict: 'A',

      link:function($scope,element,attr)
      {
        var trace = angular.trace;

        $scope.selectAnswer = function($event) {
        	var $target = $($event.currentTarget);
        	$target.addClass($target.data("correct"));
        	$target.addClass("answered");
        };
      }  
    };
  });